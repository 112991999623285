import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

export enum DisbursementType {
  DISBURSEMENT_PENDING = "DISBURSEMENT_PENDING",
  DISBURSEMENT_SUCCESS = "DISBURSEMENT_SUCCESS",
  DISBURSEMENT_ERROR = "DISBURSEMENT_ERROR",
}

export interface IParamDisbursement {
  Keyword?: string;
  CategoryId?: string;
  ApprovalStatus?: number;
  CreatedDate?: string;
  Email?: string;
  Size: number;
  Current: number;
}

export interface IParamDisbursementById {
  Id?: string;
}

export interface IDisbursementDataMaster {
  Amount: number;
  DisbursId: string;
  Email: string;
  ErrorMessage: string;
  Fee: number;
  Id: string;
  IsError: boolean;
  Name: string;
  NetAmount: number;
  NoteReq: string;
  PhoneNumber: string;
  TopUpStatus: number;
}

export interface IDisbursementResponse {
  code: number;
  status: string;
  message: string;
  data: any;
  paginate: {
    page: number;
    total_page: number;
    total_record: number;
    limit: number;
  };
}

export interface IDisbursementResponseMaster {
  Code: number;
  Status: string;
  Message: string;
  Data: IDisbursementDataMaster;
  Page: {
    Size: number;
    Pages: number;
    Rows: number;
    Current: number;
  };
}

export interface IDownloadTemplate {
  Date: string;
}

export interface IUploadPreview {
  Base64Value: string;
  fileName: string;
  categoryId: string;
}

export interface IApprovalData {
  Note: string;
  Id: string;
  Action: number;
}

export interface IUserDisburseData {
  Phone: string;
}

export interface IMiddlewareBodyDisbursementIn {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "disbursement";
  Action:
    | "get-v2"
    | "getbyid-v2"
    | "create-batch"
    | "download-template"
    | "get-categories"
    | "upload"
    | "action-approval"
    | "get-all-holding-account"
    | "get-member-by-phone";
  ResourceType: "data";
  Resource: "data";
  ResourceId: "data";
  UserId: string;
  Date: string;
  ScopePlatform: "private";
  Platform: "qoin-apps";
  Data:
    | IParamDisbursement
    | IParamCreate
    | IParamDisbursementById
    | IParamApprovalDisbursement
    | IDownloadTemplate
    | IUploadPreview
    | IApprovalData
    | IUserDisburseData;
}

export interface IParamApprovalDisbursement {
  Action: 1 | 2;
  MasterFeeId: string;
}

export interface IDetailsRowExcel {
  Id: string;
  DisbursId: string;
  PhoneNumber: string;
  Name: string;
  Email: string;
  Amount: number;
  Fee: number;
  NetAmount: number;
  NoteReq: string;
  NoteReject: string;
  ApprovalStatus: number;
  TopUpStatus: number;
  IsError: boolean;
  ErrorMessage: string;
}

export interface IParamCreate {
  Id: string;
  ClientId: number;
  CategoryId: string;
  AccountHolderSource: string;
  Type: number;
  FileName: string;
  ReferenceNumber: string;
  TotalTrx: number;
  TotalTrxAmount: number;
  DisbursementAmount: string;
  Details: IDetailsRowExcel[];
}

export interface IMiddlewareBodyDisbursementOut {
  TicketId: string;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "disbursement";
  Action:
    | "get-v2"
    | "getbyid-v2"
    | "create-batch"
    | "download-template"
    | "get-categories"
    | "upload"
    | "action-approval"
    | "get-all-holding-account"
    | "get-member-by-phone";
}

export interface ITemplateResultResp {
  Data: {
    Base64Value: string;
    FileName: string;
  };
  Ticket: string;
  Code: number;
  Status: string;
  Errors: string;
  RequestId: string;
  Command: string;
  ModuleName: string;
}

export interface IMiddlewareResponseDisbursementOut {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "disbursement";
  Action: "get-v2";
  ResourceType: null;
  Resource: null;
  ResourceId: null;
  UserId: string;
  Date: string;
  Result: IDisbursementResponseMaster | ITemplateResultResp;
  Status?: string;
  StatusCode?: number;
}

interface GetDisbursementAction {
  type: DisbursementType.DISBURSEMENT_SUCCESS;
  payload: {
    data: IMiddlewareResponseDisbursementOut | IMiddleResponse | null;
  };
}

interface SetLoadingDisbursementAction {
  type: DisbursementType.DISBURSEMENT_PENDING;
  payload?: {
    data: any;
  };
}

interface SetErrorDisbursementAction {
  type: DisbursementType.DISBURSEMENT_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IDisbursementState {
  data: IMiddlewareResponseDisbursementOut | IMiddleResponse;
  isLoading: boolean;
  error: AxiosError;
  dataDetail: any;
  isLoadingDetail: boolean;
  dataExport: any;
  isLoadingExport: boolean;
  isLoadingDDl: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  isLoadingApproval: boolean;
  dataTemplate: any;
  isLoadingTemplate: boolean;
  isLoadingCategory: boolean;
  dataCategories: any;
  isLoadingUpload: boolean;
  dataUpload: any;
  dataClient: any;
  isLoadingClient: false;
  isLoadingUserDisburse: boolean;
  dataUser: any;
}

export type DisbursementAction =
  | GetDisbursementAction
  | SetLoadingDisbursementAction
  | SetErrorDisbursementAction;
